.intro{
    padding: 0 50px
}

.hello {
    font-size: 40px
}

.i-am {
    font-size: 25px
}

.human {
    font-size: inherit
}

.ityped-cursor {
    animation: blink 1s infinite
}

@keyframes blink {
    50%{
        opacity: 1
    }
    100% {
        opacity: 0
    }
}
a:link {
    text-decoration: none
}
a:hover {
    background-color: hotpink;
    color:lime
}